import { Link } from "react-router-dom";
import "./styles.scss";

const Index = () => {
  return (
 
    <div className="marquee enable-animation">
      <ul className="marquee__content">
        {/* <li>
          <span className="marque-heading">

            29th August [National Sports Day] :{" "}
          </span>

          <a href="https://38nguk.in/" className="marquee_title_label">

            Launching the Website of 38th National Games of India, Uttarakhand.
          </a>
        </li>
     
        <li>
          <span className="marque-heading">
            
            NEWS :{" "}
          </span>
          
          <a
            href="https://38nguk.in/latest/news/1"
            className="marquee_title_label"
          >
            CM Dhami Holds Strategic Meeting on 38th National Games Preparations
          </a>
        </li>
        <li>
          <span className="marque-heading">
            OLYMPIAN FELICITATED :{" "}
          </span>
          
          <a
            href="https://38nguk.in/latest/photos"
            className="marquee_title_label"
          >

            View Photos of Olympians from Uttarakhand
          </a>
        </li> */}

        <li>
          <span
            className="marque-heading"
            onClick={() =>
              window.open(
                "https://gms.38nguk.in/general-volunteer-registration",
                "_blank"
              )
            }
          >
            For General Volunteer Registration
          </span>

          <a
            href="https://gms.38nguk.in/general-volunteer-registration"
            target="_blank"
            className="marquee_title_label mx-2"
          >
            {/* Showcase your talent and creativity from 5th to 11th November! Open to youth aged 9-18 🏆🎨. */}
            Click Here
          </a>
        </li>

        {/* <li>
          <span
            className="marque-heading"
            onClick={() =>
              window.open("https://creative-competitions.38nguk.in/", "_blank")
            }
          >
            Creative Competitions for 38th National Games (Tagline, Theme Song
            Lyrics, Hashtag, Mascot File, Sports Photography, Logo Design File)
            - For Registration
          </span>

          <a
            href="https://creative-competitions.38nguk.in/"
            target="_blank"
            className="marquee_title_label mx-2"
          >
            
            Click Here
          </a>
        </li> */}

        <li>
          <span
            className="marque-heading"
            onClick={() =>
              window.open("https://drive.google.com/file/d/1u3GYa2tm4m5-oDGGtWzmGnt5ER6YPhOj/view?usp=sharing", "_blank")
            }
          >
            38th National Games:
          </span>

          <a
            href="https://drive.google.com/file/d/1u3GYa2tm4m5-oDGGtWzmGnt5ER6YPhOj/view?usp=sharing"
            target="_blank"
            className="marquee_title_label mx-2"
          >
            {/* Showcase your talent and creativity from 5th to 11th November! Open to youth aged 9-18 🏆🎨. */}         
            Expression of Interest (EOl) for Partnership Opportunities
          </a>
        </li>
      </ul>
      <ul className="marquee__content">
        {/* <li>
          <span className="marque-heading">

            29th August [National Sports Day] :{" "}
          </span>
          
          <a href="https://38nguk.in/" className="marquee_title_label">
            
            Launching the Website of 38th National Games of India, Uttarakhand.
          </a>
        </li>
     
        <li>
          <span className="marque-heading">
           
            NEWS :{" "}
          </span>
        
          <a
            href="https://38nguk.in/latest/news/1"
            className="marquee_title_label"
          >
           
            CM Dhami Holds Strategic Meeting on 38th National Games Preparations
          </a>
        </li>
        <li>
          <span className="marque-heading">
            
            OLYMPIAN FELICITATED :{" "}
          </span>
         
          <a
            href="https://38nguk.in/latest/photos"
            className="marquee_title_label"
          >
          
            View Photos of Olympians from Uttarakhand
          </a>
        </li> */}
        <li>
          <span
            className="marque-heading"
            onClick={() =>
              window.open(
                "https://gms.38nguk.in/general-volunteer-registration",
                "_blank"
              )
            }
          >
            For General Volunteer Registration
          </span>

          <a
            href="https://gms.38nguk.in/general-volunteer-registration"
            target="_blank"
            className="marquee_title_label mx-2"
          >
            {/* Showcase your talent and creativity from 5th to 11th November! Open to youth aged 9-18 🏆🎨. */}
            Click Here
          </a>
        </li>
{/* 
        <li>
          <span
            className="marque-heading"
            onClick={() =>
              window.open("https://creative-competitions.38nguk.in/", "_blank")
            }
          >
            Creative Competitions for 38th National Games (Tagline, Theme Song
            Lyrics, Hashtag, Mascot File, Sports Photography, Logo Design File)
            - For Registration
          </span>

          <a
            href="https://creative-competitions.38nguk.in/"
            target="_blank"
            className="marquee_title_label mx-2"
          >
          
            Click Here
          </a>
        </li> */}
        <li>
          <span
            className="marque-heading"
            onClick={() =>
              window.open("https://drive.google.com/file/d/1u3GYa2tm4m5-oDGGtWzmGnt5ER6YPhOj/view?usp=sharing", "_blank")
            }
          >
            38th National Games:
          </span>

          <a
            href="https://drive.google.com/file/d/1u3GYa2tm4m5-oDGGtWzmGnt5ER6YPhOj/view?usp=sharing"
            target="_blank"
            className="marquee_title_label mx-2"
          >
            {/* Showcase your talent and creativity from 5th to 11th November! Open to youth aged 9-18 🏆🎨. */}         
            Expression of Interest (EOl) for Partnership Opportunities
          </a>
        </li>
      </ul>
    </div>
  
  );
};

export default Index;
