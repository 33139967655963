export const footer1 = [
    { name: "Home", path: "/" },
    { name: "Schedule and Results", path: "" },
    { name: "Scores", path: ""},
    { name: "Medal Tally", path: "/games/medals" },
    { name: "Visit", path: "/" },
    { name: "Teams", path: "/teams" },
    { name: "Milestones", path: "/milestone/all" },
    // { name: "Games Culture", path: "/milestone/all" },
  ]

  export const sportsOptions = [
    { name: "Aquatics", path: "/sports/aquatics" },
    { name: "Archery", path: "/sports/archery" },
    { name: "Athletics", path: "/sports/athletics" },
    { name: "Badminton", path: "/sports/badminton" },
    { name: "Basketball", path: "/sports/basketball" },
    { name: "Boxing", path: "/sports/boxing" },
    { name: "Cycling", path: "/sports/cycling" },
    { name: "Canoeing & Kayaking", path: "/sports/kayaking" },
    { name: "Fencing", path: "/sports/fencing" },
   
    // { name: "Fencing", path: "/sports/fencing" },
    // { name: "Football", path: "/sports/football" },
    // { name: "Gymnastics", path: "/sports/gymnastic" },
    // { name: "Hockey", path: "/sports/hockey" },
    // { name: "Handball", path: "/sports/handball" },
    // { name: "Judo", path: "/sports/judo" },
    // { name: "Kabaddi", path: "/sports/kabaddi" },
    // { name: "Kayaking", path: "/sports/kayaking" },
    
  
  ];

  export const footer3 = [
   
    { name: "Football", path: "/sports/football" },
     { name: "Golf", path: "/sports/golf" },
    { name: "Gymnastics", path: "/sports/gymnastic" },
    { name: "Hockey", path: "/sports/hockey" },
    { name: "Handball", path: "/sports/handball" },
    { name: "Judo", path: "/sports/judo" },
    { name: "Kabaddi", path: "/sports/kabaddi" },
    // { name: "Kayaking", path: "/sports/kayaking" },
    { name: "Kho-Kho", path: "/sports/kho-kho" },
    // { name: "Karate", path: "/sports/karate" },
    { name: "Lawn Tennis", path: "/sports/lawntennis" },
    // { name: "Kho-Kho", path: "/sports/kho-kho" },
    // { name: "Karate", path: "/sports/karate" },
    // { name: "Lawn Ball", path: "/sports/lawnball" },
    // { name: "Modern Pentathlon", path: "/sports/pentathlon" },
    // { name: "Mallakhamb", path: "" },
    // { name: "Netball", path: "/sports/netball" },
    // { name: "Rowing", path: "/sports/rowing" },
    // { name: "Rugby", path: "/sports/rugby" },
    // { name: "Shooting", path: "/sports/shooting" },
    // { name: "Squash", path: "/sports/squash" },
    // { name: "Sailing", path: "/sports/sailing" },
    // // { name: "Sepak Takraw", path: "/sports/sepak-takraw" },
    // { name: "Table Tennis", path: "/sports/table-tennis" },
    // { name: "Tennis", path: "/sports/tennis" },
    // { name: "Triathlon", path: "/sports/triathlon" },  
    // { name: "Taekwondo", path: "/sports/taekwondo" },
   
 
  ];
  

  export const footer4 = [
  
    { name: "Lawn Bowl", path: "/sports/lawnball" },
    { name: "Modern Pentathlon", path: "/sports/pentathlon" },
    // { name: "Shooting", path: "" },
    { name: "Netball", path: "/sports/netball" },
    { name: "Rowing", path: "/sports/rowing" },
    { name: "Rugby", path: "/sports/rugby" },
    { name: "Volleyball", path: "/sports/volleyball" }, 
    { name: "Weightlifting", path: "/sports/weightlifting" },  
    { name: "Wushu", path: "/sports/wushu" },  
    { name: "Wrestling", path: "/sports/wrestling" }, 

  ];

  export const footer5 = [
    
  
    // { name: "Yoga", path: "/sports/yoga" },
  
    { name: "Shooting", path: "/sports/shooting" },
    { name: "Squash", path: "/sports/squash" },
    { name: "Sailing", path: "/sports/sailing" },
    // { name: "Sepak Takraw", path: "/sports/sepak-takraw" },
    { name: "Table Tennis", path: "/sports/table-tennis" },
    // { name: "Tennis", path: "/sports/tennis" },
    { name: "Triathlon", path: "/sports/triathlon" },  
    { name: "Taekwondo", path: "/sports/taekwondo" },
    { name: "Equestrian", path: "/sports/equestrian" },
  ];