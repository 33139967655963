import "./chatBotMoga.css";
import moga from "../../assets/home2/Body/Logo.png";
import mualiimg from "../../assets/home2/Body/muali.png"
import { IoCloseSharp } from "react-icons/io5";


const ChatBothome = ({ setuserName, userName, setOpenChat,setOpenChatBox }) => {
  return (
    <div className="chat-home-main">
     <IoCloseSharp className="chatbot-close-button" onClick={() => setOpenChatBox(false)}/>
      <div className="chat-home-head">
      <img loading="lazy"    src={moga} alt="Logo" className="national-Logo" />
        <img loading="lazy" src={mualiimg} alt="Logo" className="moga-Logo" />
        <h1> Need help?</h1>
      </div>
      <div className="chat-home-body">
        <input
          type="text"
          name="username"
          value={userName}
          placeholder="Enter your name"
          className="chatbot-home-username"
          onChange={(e) => {
            setuserName(e.target.value);
            setOpenChat(false);
          }}
        />
        <button
          type="submit"
          id="start-chat"
          className="chatbot-home-start"
          onClick={() => setOpenChat(true)}
        >
          Start
        </button>
      </div>
    </div>
  );
};

export default ChatBothome;
